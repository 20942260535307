import React, { useEffect } from 'react';
import { getOrdersDisbursement } from '../../bdb-actions/bdbDocumentsActions';
import { BackLink } from '../../bdb-components/BackLink/BackLink';
import Table from '../../bdb-components/Table/Table';
import { useDispatch, useSelector } from 'react-redux'
import Loading from '../../bdb-components/Modals/Loading';

const OrderSendsView = () => {

  const dispatch = useDispatch();
  const { orders, loading } = useSelector( state => state.documentsReducer );

  useEffect(() => {
    dispatch( getOrdersDisbursement() );
  }, [dispatch])

  const handleClick = (e) => {
    dispatch( getOrdersDisbursement() );
  }
  
  return (
    <section className='_order-sends_container'>
       <Loading show={loading}/>
        <BackLink url={-1}/>
        <h2 className='_order-sends_space'>Orden de Desembolso</h2>
        <div className='_order-sends_grouping-table'>
            <div className='_order-sends_box-tab'>
                <span className='_order-sends-tittle'>Enviadas</span>
                <span className='_order-sends-count'>{orders.length}</span>
            </div>
            <div className='_order-sends_update' onClick={ handleClick }>
              <span>Actualizar</span>
              <div className='_oder_sends-refresh-icon'></div>
            </div>
            <Table data={orders}/>
        </div>
    </section>
  )
}

export default OrderSendsView