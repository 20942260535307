export const productsDocuments = [
    {"id": "BANCOLDEX", "label": "Bancoldex"},
    {"id": "CC02", "label": "Cartas Cupo"},
    {"id": "38", "label": "Cartera Refinanciada"},
    {"id": "CC01", "label": "Crediservice Comercial"},
    {"id": "CF01", "label": "Crediporvenir Fija"},
    {"id": "CV01", "label": "Crediporvenir Variable"},
    {"id": "DS01", "label": "Desarrollo Sostenible"},
    {"id": "FINAGRO", "label": "Finagro"},
    {"id": "FINDETER", "label": "Findeter"},
    {"id": "GB01", "label": "Garantías Bancarias"},
    {"id": "LIQUIDEZ", "label": "Liquidez"},
    {"id": "MICROCREDITO", "label": "Microcrédito"},
    {"id": "571", "label": "Rotativo Plus"},
    {"id": "570", "label": "Rotativo Pyme"},
    {"id": "01", "label": "Ordinaria"},
    {"id": "STIF01", "label": "Sustitución de Inversión Forzosa"},
    {"id": "100", "label": "Sustitución de Pasivos Comercial"}
]
export const typeIdentification = [
    {'id' : 'C', 'label' : 'Cédula de ciudadanía'},
    {'id' : 'E', 'label' : 'Cédula de extranjería'},
    {'id' : 'P', 'label' : 'Pasaporte'},
    {'id' : 'N', 'label' : 'NIT'}

]

export const othersItem = {
    "documentType": "IC - Orden de Desembolso",
    "friendlyName": "Otros (si aplica)",
    "checkExpeditionDate": false,
    "nameForDb": "Otros (si aplica)",
    "index": 0
}

export const documentsWithXls = [
    'Orden de desembolso',
    'Archivo plano'
]
export const documentsChangeUpload = [
    {
        "documentType": "IC - Certificado de Existencia y Representacion Legal",
        "friendlyName": "Certificado de existencia y representación legal",
        "checkExpeditionDate": false
    },
    {
        "documentType": "IC - Acta Posesion - Alcalde",
        "friendlyName": "Acta de posesión del alcalde",
        "checkExpeditionDate": false
    },
]

export const initFormValues = {
    productObj : '',
    identiCustomer : '',
    typeCustomerObj : '',
    numberControl : '',
    creditProject : ''
}

export const elasticRoleField = 'cnUserRol';
export const genericRole = 'genericRole';