export const GET_DOCUMENT_TYPE = 'GET_DOCUMENT_TYPE';
export const GET_DOCUMENT_TYPE_SUCCESS = 'GET_DOCUMENT_TYPE_SUCCESS';
export const GET_DOCUMENT_TYPE_FAIL = 'GET_DOCUMENT_TYPE_FAIL';

export const POST_DOCUMENT_TYPE = 'POST_DOCUMENT_TYPE';
export const POST_DOCUMENT_TYPE_SUCCESS = 'POST_DOCUMENT_TYPE_SUCCESS';
export const POST_DOCUMENT_TYPE_FAIL = 'POST_DOCUMENT_TYPE_FAIL';

export const GET_DOCUMENT_REQUEST_NUMBER = 'GET_DOCUMENT_REQUEST_NUMBER';
export const GET_DOCUMENT_REQUEST_NUMBER_SUCESS = 'GET_DOCUMENT_REQUEST_NUMBER_SUCESS';
export const GET_DOCUMENT_REQUEST_NUMBER_FAIL = 'GET_DOCUMENT_REQUEST_NUMBER_FAIL';

export const SEND_DOCUMENTS_SUCCESS = 'SEND_DOCUMENTS_SUCCESS';
export const UPLOAD_DOCUMENTS_CACHE = 'UPLOAD_DOCUMENTS_CACHE';

export const POST_ORDER_START = 'POST_ORDER_START';
export const POST_ORDER_SUCCESS = 'POST_ORDER_SUCCESS';
export const POST_ORDER_FAIL = 'POST_ORDER_FAIL';

export const PREVIEW_DOCUMENT = 'PREVIEW_DOCUMENT';
export const RESET_DOCUMENT_PREVIEW = 'RESET_DOCUMENT_PREVIEW';
export const UPDATE_PREVIEW_PROGRESS = 'UPDATE_PREVIEW_PROGRESS';

export const RESET_MODAL = 'RESET_MODAL';
export const RESET_INFO = 'RESET_INFO';
export const SET_ERROR = 'SET_ERROR';
export const SET_DELETE_MODAL = 'SET_DELETE_MODAL';
export const SET_INIT_FORM_VALUES = 'SET_INIT_FORM_VALUES';
export const SET_INIT_DOCUMENTS_TYPES = 'SET_INIT_DOCUMENTS_TYPES';

export const START_LOADING = 'START_LOADING';
export const FINISH_LOADING = 'FINISH_LOADING';
export const SHOW_MODAL_ERROR = 'SHOW_MODAL_ERROR';
export const HIDE_MODAL_ERROR = 'HIDE_MODAL_ERROR';
export const SHOW_CONFIRMATION_MODAL = 'SHOW_CONFIRMATION_MODAL';
export const ORDERS_DISBURSEMENT_SUCESS = 'ORDERS_DISBURSEMENT_SUCESS';
