import React from 'react';
import { pdfjs } from "react-pdf";
import pdfjsWorker from "pdfjs-dist/build/pdf.worker.entry";
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { Provider } from 'react-redux';
import store from './store';
import * as CryptoJS from "crypto-js";
import HeaderComponent from './app/bdb-components/HeaderComponent';
import DocumentsView from './app/bdb-views/Documents/DocumentsView';
import { HomePage } from './app/bdb-components/HomePageComponent/HomePage';
import ReportOrder from './app/bdb-views/ReportOrderView/ReportOrder';
import OrderSendsView from './app/bdb-views/OrderSends/OrderSendsView';

  const getParam = sVar => {
    return unescape(window.location.search.replace(new RegExp('^(?:.*[&\\?]' +
        (escape(sVar) ? escape(sVar).replace(/[\.\+\*]/g, '\\$&') : '') + '(?:\\=([^&]*))?)?.*$', 'i'), '$1'));
  }

    const encryptUsingAES256 = (value, iv, key) => {
        const _key = CryptoJS.enc.Utf8.parse(key);
        const _iv = CryptoJS.enc.Hex.parse(iv);
        return CryptoJS.AES.encrypt(
            value, _key, {
                keySize: 128,
                iv: _iv,
                mode: CryptoJS.mode.CBC,
                padding: CryptoJS.pad.Pkcs7
            }).toString();
    }

    global.decryptUsingAES256 = (value, iv, key) => {
        const _key = CryptoJS.enc.Utf8.parse(key);
        const _iv = CryptoJS.enc.Hex.parse(iv);
        return CryptoJS.AES.decrypt(
            value, _key, {
                keySize: 128,
                iv: _iv,
                mode: CryptoJS.mode.CBC,
                padding: CryptoJS.pad.Pkcs7
            }).toString(CryptoJS.enc.Utf8);
    }

    global.b64_to_utf8 = str => {
        return decodeURIComponent(escape(window.atob( str )));
    }

  function initValues() {
    const isAuthenticated = sessionStorage.getItem('isAuthenticated');
    if (!isAuthenticated) {
        pdfjs.GlobalWorkerOptions.workerSrc = pdfjsWorker;
        let keyToken = getParam('cryptKeyToken');
        let token = getParam('cryptToken');
        let documentNumberAdviser = getParam('cryptDocumentNumberAdviser');
        let rolUser = getParam('cryptRolUser');
        let username = getParam('cryptUsername');
        let userLogin = getParam('cryptUserLogin');
        let officeCode = getParam('cryptOfficeCode');
        let ip = getParam('cryptIp');
        let cnUserRol = getParam('cnUserRol');

        const cryptKeyToken = global.b64_to_utf8(keyToken);
        const cryptToken = global.b64_to_utf8(token);
        const cryptRolUser = global.b64_to_utf8(rolUser);
        const cryptUsername = global.b64_to_utf8(username);
        const cryptUserLogin = global.b64_to_utf8(userLogin);
        const cryptDocumentNumberAdviser = documentNumberAdviser;
        const cryptOfficeCode = global.b64_to_utf8(officeCode);
        const cryptIp = global.b64_to_utf8(ip);
        const cryptCnUserRol = global.b64_to_utf8(cnUserRol);

        keyToken = global.decryptUsingAES256(global.b64_to_utf8(keyToken), global.b64_to_utf8(documentNumberAdviser), global.b64_to_utf8(process.env.REACT_APP_AES_KEY));
        token = global.decryptUsingAES256(global.b64_to_utf8(token), global.b64_to_utf8(documentNumberAdviser), global.b64_to_utf8(process.env.REACT_APP_AES_KEY));
        rolUser = global.decryptUsingAES256(global.b64_to_utf8(rolUser), global.b64_to_utf8(documentNumberAdviser), global.b64_to_utf8(process.env.REACT_APP_AES_KEY));
        username = global.decryptUsingAES256(global.b64_to_utf8(username), global.b64_to_utf8(documentNumberAdviser), global.b64_to_utf8(process.env.REACT_APP_AES_KEY));
        userLogin = global.decryptUsingAES256(global.b64_to_utf8(userLogin), global.b64_to_utf8(documentNumberAdviser), global.b64_to_utf8(process.env.REACT_APP_AES_KEY));
        officeCode = global.decryptUsingAES256(global.b64_to_utf8(officeCode), global.b64_to_utf8(documentNumberAdviser), global.b64_to_utf8(process.env.REACT_APP_AES_KEY));
        ip = global.decryptUsingAES256(global.b64_to_utf8(ip), global.b64_to_utf8(documentNumberAdviser), global.b64_to_utf8(process.env.REACT_APP_AES_KEY));
        cnUserRol = global.decryptUsingAES256(global.b64_to_utf8(cnUserRol), global.b64_to_utf8(documentNumberAdviser), global.b64_to_utf8(process.env.REACT_APP_AES_KEY));
        documentNumberAdviser = global.b64_to_utf8((documentNumberAdviser));

      if (!!keyToken && !!token && !!documentNumberAdviser && !!rolUser && !(!!sessionStorage.getItem('token'))) {
        sessionStorage.clear();
        sessionStorage.setItem('keyToken', cryptKeyToken);
        sessionStorage.setItem('token', cryptToken);
        sessionStorage.setItem('documentNumberAdviser', cryptDocumentNumberAdviser);
        sessionStorage.setItem('rolUser', cryptRolUser);
        sessionStorage.setItem('username', cryptUsername);
        sessionStorage.setItem('userLogin', cryptUserLogin);
        sessionStorage.setItem('officeCode', cryptOfficeCode);
        sessionStorage.setItem('ip', cryptIp);
        sessionStorage.setItem('cnUserRol', cryptCnUserRol);
        sessionStorage.setItem('isAuthenticated', encryptUsingAES256('true', documentNumberAdviser, global.b64_to_utf8(process.env.REACT_APP_AES_KEY)));
      } 
    } else {
      sessionStorage.setItem('isAuthenticated', encryptUsingAES256('true', global.b64_to_utf8(sessionStorage.getItem('documentNumberAdviser')), global.b64_to_utf8(process.env.REACT_APP_AES_KEY)));
    }
  }

function App() {
  console.log(`Estoy en ambiente --> ${process.env.REACT_APP_ENV}`)
  initValues();
  const cryptIsAuthenticated = sessionStorage.getItem('isAuthenticated');
  const isAuthenticated = global.decryptUsingAES256(cryptIsAuthenticated,  global.b64_to_utf8(sessionStorage.getItem('documentNumberAdviser')), global.b64_to_utf8(process.env.REACT_APP_AES_KEY));
  const isAuth = isAuthenticated === 'true';

  return (
    <Router>
      <HeaderComponent />
      <Provider store={store}>
          <div className="container">
              <Switch>
                  <Route exact path="/" component={HomePage}/> 
                  <Route exact authed={isAuth} path="/orderDisbursement" component={ReportOrder}/>
                  <Route exact authed={isAuth} path="/documents" component={DocumentsView}/>
                  <Route exact authed={isAuth} path="/order-sends" component={OrderSendsView}/>
              </Switch>
          </div>
      </Provider>
    </Router>
  );
}

export default App;
