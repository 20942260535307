import React, { useEffect } from 'react'
import { useHistory } from 'react-router'

export const HomePage = () => {
    const history = useHistory();
    const redirectToPatch = () => {
        history.push('/orderDisbursement')

    }
    useEffect(() => {
        redirectToPatch();
    })
    return (
        <>
            
        </>
    )
}
