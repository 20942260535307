import React from 'react';
import Modal from "react-bootstrap/Modal";
import '../../../assets/styles/ComponentsStyles/modals.scss'

export const ErrorModal = ( {show = false, handleButton}) => {
  return (
    <Modal show={show} centered >
        <Modal.Header bsPrefix="header-modal">
            <span className="icon-error"></span>
        </Modal.Header>                
        <Modal.Body bsPrefix="body-modal">
            <p className="tittle-modal">Se presentó un error inesperado</p>
        </Modal.Body>
        <Modal.Footer bsPrefix="footer-modal">
            <button 
                className="footer-button-modal width-100"
                id="Accept"
                onClick={ handleButton }>Aceptar</button>
        </Modal.Footer>
    </Modal>
  )
    
};
