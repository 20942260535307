import React, { useEffect, useState } from 'react';
import '../../App.scss'

const HeaderComponent = () => {

    const [nameUser, setNameUser] = useState(null);

    useEffect(() => {
        const sessionUser = sessionStorage.getItem('username');
        setNameUser(global.decryptUsingAES256(
            sessionUser,
            global.b64_to_utf8(sessionStorage.getItem('documentNumberAdviser')), 
            global.b64_to_utf8(process.env.REACT_APP_AES_KEY)
        ));
    },[]);

    const summitLogout = () => {
        sessionStorage.clear();
        window.location.href = process.env.REACT_APP_LOGOUT_ENDPOINT;
    }


    return ( 
        <div className="row content-header">
            <div className="col-12 div-fixed background-header div-fixed">
                <div className="row width-full margin-0">
                    <div className="content-header">
                        <div className="icon-bdb-header"></div>
                        <div className="logout-header font-text-header">
                            <div className="flex-self-center user-icon"></div>
                            <div className="flex-self-center">{nameUser}</div>
                            <div className="flex-self-center separator-header">|</div>
                            <div className="flex-self-center logout-icon"></div>
                            <div className="flex-self-center cursor-pointier"
                                onClick={() => summitLogout()}>Cerrar Sesión</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
     );
}
 
export default HeaderComponent;