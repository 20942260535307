import React, { Fragment, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector  } from 'react-redux';

import { v4 as uuid } from 'uuid';

import Loading from '../../bdb-components/Modals/Loading';
import Preview from "../../bdb-components/Preview/Preview";
import { DeleteModal } from '../../bdb-components/Modals/DeleteModal';
import { ErrorModal } from '../../bdb-components/Modals/ErrorModal';

import {
    uploadCache, resetData, previewDocument, resetDocumentPreview, previewTifDocument, showDeleteModal,
    setInitDocumentsTypes, setInitFormValues, updateDocument, deleteDocument, transferDocuments, hideModalError
} from '../../bdb-actions/bdbDocumentsActions';
import { sessionStorageGetItem } from '../../bdb-services/global/global';
import { documentsChangeUpload, documentsWithXls, initFormValues, othersItem } from '../../../constants';
import '../../../App.scss';
import '../../../assets/styles/ViewsStyles/documents.scss';
import '../../../assets/styles/buttons.scss';
import '../../../assets/styles/inputs.scss';
import '../../../assets/styles/margin.scss';

function DocumentsView() {

    const dispatch = useDispatch();
    const history = useHistory();
    const [disabledButton, setDisabledButton] = useState(true);
    const [enableRedirect, setEnableRedirect] = useState(false);

    const typesDocs = useSelector(state => state.documentsReducer.documentsTypes);
    const previewedDocument = useSelector( state => state.documentsReducer.previewedDocument );
    const dataOrder = useSelector(state => state.documentsReducer.dataForm);
    const loading = useSelector(state => state.documentsReducer.loading);
    const error = useSelector( state => state.documentsReducer.error );

    const [ formData, setFormData ] = useState(typesDocs);
    const [ currentDocumentInfo, setCurrentDocumentInfo ] = useState( previewedDocument );
    const [ resetDocumentInfo, setResetDocumentInfo ] = useState( false );
    const showDeleteModalFlag = useSelector(state => state.documentsReducer.showDeleteModal);
    const [ documentsHidden, setDocumentsHidden] = useState([]);
    const { productObj, creditProject, numberControl } = dataOrder;

    useEffect( () => {
        if ( resetDocumentInfo ) {
            dispatch( resetDocumentPreview( currentDocumentInfo ) );
            setResetDocumentInfo( false );
        }
    }, [ resetDocumentInfo, currentDocumentInfo, dispatch ] )

    useEffect( () => {
        if ( previewedDocument ) {
            setCurrentDocumentInfo( previewedDocument );
        }
    }, [ previewedDocument ] )

    useEffect(() => {
        setFormData( [...typesDocs] );
        setDisabledButton( validityButtonSend() );
    }, [ typesDocs, dataOrder ]);

    useEffect(() => {
        if (enableRedirect && !loading){
            returnDisbursementOrder();
            setEnableRedirect(false);
            dispatch(resetData());
        }
    },[loading ])

    useEffect(() => {
        const initValues = () => {
            if( typesDocs.length === 0 ){
                dispatch( setInitDocumentsTypes(!!sessionStorageGetItem('typesDocuments') ? sessionStorageGetItem('typesDocuments') :[] ));
                dispatch( setInitFormValues(!!sessionStorageGetItem('formOrder') ? sessionStorageGetItem('formOrder') : initFormValues) )
            }
        };
        initValues();
    }, [])
    

    const cancelProcess = () => {
        window.location.href = `${process.env.REACT_APP_LOGOUT_ENDPOINT}home-adviser/false#no-back-button`;
        sessionStorage.clear();
    }

    const returnDisbursementOrder = () =>{
        history.push('/orderDisbursement');
      }

    const handleChangeFile = ( e, key ) => {
        let temporalForm = [...formData];
        temporalForm[ key ][ 'file' ] = e;
        temporalForm[ key ][ 'upload' ] = true;
        if ( temporalForm[ key ].friendlyName.includes( 'Otros' ) ) {
            let anotherItem = { ...othersItem };
            anotherItem.nameForDb = `${ anotherItem.friendlyName } ${ uuid() }`;
            anotherItem.index = key + 1;
            temporalForm.push( anotherItem );
        }
        if ( documentsChangeUpload.map( document => document.friendlyName).includes( temporalForm[ key ].friendlyName ) )  {
            const documentsToHide = documentsChangeUpload.filter( document => document.friendlyName !== temporalForm[ key ].friendlyName);
            documentsToHide.forEach( documentHide => {
                const index = typesDocs.findIndex( document => document.friendlyName === documentHide.friendlyName );
                if ( index !== -1 ) {
                    temporalForm[ index ]['show'] = false;
                    setDocumentsHidden( [...documentsHidden, index])
                } 
            })
        }
        dispatch( updateDocument( temporalForm, key, { ...dataOrder } ) );
    }

    const handleErrorModalButton = () => {
        dispatch( hideModalError() );
    };

    const handleSubmit = (e) => {
        
        dispatch( transferDocuments( [ ...formData ], { ...dataOrder } ) );
        setEnableRedirect(true);
    }

    function isDocumentRequired ( type ) {
        return !type.friendlyName.toLowerCase().includes('(si aplica');
    }

    function validityButtonSend() {
        const validates = typesDocs.map(document => {
            if ( isDocumentRequired(document) && document.show !== false ){
                return !!document.upload
                
            } else return true;
          });
        return validates.length === 0 ? true: validates.includes(false);
    }

    const handlePreviewOnClick = ( index ) => {
        const file = formData[ index ].file;
        const ext = file.name ? file.name.split( '.' ).pop().toLowerCase() : '';
        if ( ext && ( ext.indexOf( 'tif' ) !== -1 ) ) {
            dispatch( previewTifDocument( formData, index ) );
        } else {
            dispatch( previewDocument( formData, index ) );
        }
    }

    const openDeleteModal = ( index ) => {
        setCurrentDocumentInfo( formData[ index ] );
        dispatch( showDeleteModal(true) );
    };

    const handleDeleteUploadedDocument = ( { target } ) => {
        if ( target.id === "Delete" ) {
            let temporalForm = [ ...formData ];
            dispatch( deleteDocument( `${ creditProject }${ numberControl }`, temporalForm[ currentDocumentInfo.index ] ) );
            setResetDocumentInfo( true );
            handlePostDelete( temporalForm );
            dispatch( showDeleteModal( false ) );
        } else if ( target.id === "Cancel" ) {
            dispatch( showDeleteModal(false) );
        }
    };

    function handlePostDelete( documents ) {
        resetDeletedDocAttributes( documents );
        showHideDocs( documents );
        verifyExistsEmptyOthers( documents );
        setIndexes2OtherDocs( documents );
        dispatch( uploadCache( documents ) );
    }

    function resetDeletedDocAttributes( documents ) {
        delete documents[ currentDocumentInfo.index ].src;
        delete documents[ currentDocumentInfo.index ].upload;
        delete documents[ currentDocumentInfo.index ].file;
        delete documents[ currentDocumentInfo.index ].cloudPath;
        if ( documents[ currentDocumentInfo.index ].expeditionDate ) {
            delete documents[ currentDocumentInfo.index ].expeditionDate;
            documents[ currentDocumentInfo.index ].enableUpload = false;
        }
    }

    function showHideDocs( documents ) {
        if ( documentsChangeUpload.map( document => document.friendlyName ).includes( documents[ currentDocumentInfo.index ].friendlyName ) ) {
            documentsHidden.forEach( index => {
                documents[ index ][ 'show' ] = true;
            } );
            setDocumentsHidden( [] );
        }
    }

    function verifyExistsEmptyOthers( documents ) {
        const isOther = currentDocumentInfo.friendlyName.includes( 'Otros' );
        if ( !isOther ) {
            return;
        }
        for ( let i = documents.length - 1; i >= 0 ; i-- ) {
            if ( documents[ i ].friendlyName.includes( 'Otros' ) && !documents[ i ].upload ) {
                documents.splice( i, 1 );
                break;
            }
        }
    }

    function setIndexes2OtherDocs( documents ) {
        return documents.map( ( document, i ) => {
            document.index = i;
            return document;
        } );
    }

    function isExcelFile( documentName, extArray ) {
        for( let ext of extArray ) {
            if ( documentName.indexOf( `.${ ext }` ) !== -1 ) {
                return true;
            }
        }
        return false;
    }
    function getIcon( name ) {
        return documentsWithXls.includes(name) ? 'xls-icon' : 'doc-icon';
    }
    function acceptXls(name) {
        return documentsWithXls.includes(name) ? ' .xls, .xlsx' : ' .pdf, .png, .tiff, .jpg, .tif, .jpeg';
    }
    const handleExpeditionDate = ( e, key) => {
        let temporalForm = [...formData];
        let expeditionDate = e.nativeEvent.inputType === 'deleteContentBackward' ? '' : e.target.value.replace(/[^\d,/]/gi, '');
        expeditionDate = formattedDate(expeditionDate);
        temporalForm[key]['enableUpload'] = validityDate(temporalForm[key].validity.days, expeditionDate);
        temporalForm[key]['expeditionDate'] = expeditionDate;
        dispatch( uploadCache( temporalForm ) );

    }
    function formattedDate( date ){
        if ( !!date) {
            return (date.length === 2 || date.length === 5) ? `${date}/` : date; 
        } else {
            return date;
        }
    }
    function validityDate( days, date){
        let dateSplit = date.split('/');
        dateSplit = `${dateSplit[2]}/${dateSplit[1]}/${dateSplit[0]}`;
        const dateNow = new Date(Date.now())
        const expeditionDate = new Date(dateSplit);
        const validityDate = dateNow.getTime() - (24*60*60*1000) * days;
        return expeditionDate.getTime() >= validityDate && expeditionDate.getTime() <= dateNow.getTime();
    }
    function getEnableButton( type ) {
        return type.enableUpload !== undefined ? !type.enableUpload : !!type.checkExpeditionDate;
    }
    function showExpeditionError( type ){
        if (!!type.expeditionDate) {
            return type.expeditionDate.length === 10 ? getEnableButton(type) : false;
        } else return false;
    }

    return (
        <Fragment>
            <Loading show={loading} />
            { currentDocumentInfo && currentDocumentInfo.src ?
                <Preview
                    documentInfo={ currentDocumentInfo }
                    setDocumentInfo={ setCurrentDocumentInfo }
                    setResetDocumentInfo={ setResetDocumentInfo }
                    handlePreviewDelete={ handleDeleteUploadedDocument}
                /> :
                <div>
                    <div className='background-to-scroll'>
                        <div className='cancel-process' onClick={cancelProcess}>
                            <div className='cancel-icon'></div>
                            <div className='cancel-text'>Cancelar proceso</div>
                        </div>
                        <div className='d-flex content-docs-header'>
                            <div className='d-flex'>
                                <div className="Tittle-documents">Documentación requerida - </div>
                                <div className="Tittle-documents product-documents">&nbsp;{productObj.label}</div>
                            </div>
                            <div className="d-flex margin-sub-doc">
                                <div className="icon-info-title"></div>
                                <div className="subtitle-documents">Los documentos con (*) son obligatorios. Recuerda que puedes subir archivos en PDF, XLS, TIFF, JPG o PNG.</div>
                            </div>
                        </div>
                    </div>
                    <div className='content-docs-list'>
                        { formData.length !== 0 ?
                            formData.map((type, key) => {
                            return type.show !== false && 
                                (<div key={key} className="div-content">
                                        <div className="d-flex div-document content-document-upload">
                                            {!!type.upload ? <div className="image-upload" ></div> : <div className={`image-doc ${getIcon(type.friendlyName)}`}></div> }
                                            <div className='box-align-doc'>
                                                <div className="content-tex-doc">
                                                    <div className='required-doc'>{isDocumentRequired(type) && '*'}</div>
                                                    <div className="text-document-type">{type.friendlyName}</div>
                                                </div>
                                                { !!type.upload &&
                                                <div className='text-name-file'>{type.file.name}</div>
                                                }
                                            </div>
                                            {!!type.upload ? null :
                                                <div className="button-doc">
                                                    <div className={`div-button-doc d-flex ${getEnableButton(type) ? 'button-doc-disable' : ''}`}>
                                                        <div className={`image-button-doc ${getEnableButton(type) ? 'image-button-doc-disable' : ''}`}></div>
                                                        <div className="text-button-doc">Subir documento</div>
                                                        <input type="file" name="file" accept={`${acceptXls(type.friendlyName)}`}
                                                               onChange={(e) => handleChangeFile(e.target.files[0], key)}
                                                               disabled={getEnableButton(type)}/>
                                                    </div>
                                                </div>
                                            }
                                        </div>
                                        {
                                            (!type.upload && type.checkExpeditionDate) &&
                                                <div className="div-upload-doc">
                                                    <div className="content-upload-doc-exp max-hg">
                                                        <div className='expdition-error ml-60'>{showExpeditionError(type) && 'Fecha incorrecta, supera el tiempo de vigencia.'}</div>
                                                        <div className='d-flex ml-60 align-items-center'>
                                                            <div className='text-upload-doc'>Expedición:</div>
                                                            <input 
                                                                className={`expedition-input ${showExpeditionError(type) && 'expedition-input-error'}`}
                                                                placeholder='DD/MM/AAAA'
                                                                onChange={(e) => handleExpeditionDate( e, key)}
                                                                value={!!type.expeditionDate ? type.expeditionDate : ''}
                                                                maxLength={10}
                                                            />
                                                        </div>
                                                    </div>
                                                </div> 
                                        }
                                        {!!type.upload ?
                                            <div>
                                                <div className="div-upload-doc"></div>
                                                <div className={`content-upload-doc d-flex ${!type.checkExpeditionDate ? 'justify-content-end' : ''}`}>
                                                    { type.checkExpeditionDate &&
                                                        <div className='d-flex align-items-center'>
                                                            <div className='text-upload-doc ml-60'>Expedición</div>
                                                            <input 
                                                                disabled={true}
                                                                className='expedition-input'
                                                                value={!!type.expeditionDate ? type.expeditionDate : ''} 
                                                                readOnly="readonly"
                                                            />
                                                        </div> 

                                                    }
                                                    <div className={`div-delet ${type.checkExpeditionDate ? 'ml-212' : ''}`}>
                                                        <div className="image-delete float-left image-margin"></div>
                                                        <div
                                                            className="text-upload-doc"
                                                            onClick={ () => openDeleteModal( key ) }>
                                                            Eliminar
                                                        </div>
                                                    </div>
                                                    {
                                                        isExcelFile( type.file.name, [ 'xls', 'xlsx', 'xlsb', 'csv' ] ) ? undefined : <>
                                                            <span className="divider">|</span>
                                                            <div className="div-preview">
                                                                <div className="image-preview float-left cursor" ></div>
                                                                <div
                                                                    className="text-upload-doc"
                                                                    onClick={ () => handlePreviewOnClick( key ) }>
                                                                    Previsualizar
                                                                </div>
                                                            </div> </>
                                                    }
                                                </div>
                                            </div>
                                            : null
                                        }
                                    </div>) })
                            : null
                        }
                        <div className="div-content-button mb-5">
                            <button
                                disabled={disabledButton}
                                onClick={handleSubmit}
                                className="btn-order">Enviar</button>
                        </div>
                    </div>
                </div>
            }
            <DeleteModal
                show={ showDeleteModalFlag }
                bodyContent={ <p>Esta operación no se podrá deshacer.</p> }
                handleButton={ handleDeleteUploadedDocument } />
            <ErrorModal
                show={ error }
                handleButton={ handleErrorModalButton } />
       </Fragment>
    );
}

export default DocumentsView;
