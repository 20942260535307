import React from 'react';
import MaterialTable from 'material-table';
import { ThemeProvider, createTheme } from '@mui/material';
import { forwardRef } from 'react';
import Clear from '@material-ui/icons/Clear';
import ArrowDownward from '@material-ui/icons/ArrowDownward';

const cols = [
    { title: 'Fecha radicación', field: 'dateAssignment', cellStyle: { width: '16%', padding: '2vh 2vw'}, defaultSort: 'desc' },
    { title: 'Nº de identificación', field: 'document', cellStyle: { width: '17%', padding: '2vh 2vw'}, render: rowData => <div>{`${rowData.typeDocument} ${rowData.document.replace(/.(?=(?:.{3})+$)/g, '$&.')}`}</div> },
    { title: 'Producto', field: 'product', cellStyle: { width: '16%',padding: '2vh 2vw'} },
    { title: 'Número de control', field: 'numberControl', cellStyle: { with: '16%', padding: '2vh 2vw'} },
    { title: 'Proyecto crediticio', field: 'creditProject', cellStyle: { width: '35%', padding: '2vh 2vw'} }
  ];

const CustomSearch = forwardRef ((pros, ref) => (
    <div className='_table_search-icon'>
    </div>
  ));

const tableIcons = {
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <CustomSearch {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />)
}
const Table = ( {data} ) => {
    const defaultMaterialTheme = createTheme();
  return (
    <div className='_table_container-table' style={{ maxWidth: '100%' }}>
        <ThemeProvider theme={defaultMaterialTheme}>
            <MaterialTable
                icons={tableIcons}
                columns={ cols }
                data={ data }
                localization={{
                  toolbar: {
                      searchPlaceholder: 'Buscar'
                  },
                  body: {
                    emptyDataSourceMessage: "No se encontraron resultados"
                  }
                }}
                style={{
                  backgroundColor: '#f6f8fa',
                  borderRadius: '0px',
                  boxShadow: 'none',
                  border: '0px',

                }}
                options={{
                  headerStyle: {
                    backgroundColor: '#f6f8fa',
                    color: '#4a4a4a',
                    fontWeight: 600,
                    fontSize: '12px',
                    padding: '2vh 2vw'
                  },
                  paging: false,
                  rowStyle: {
                      color: '#4a4a4a',
                      fontSize: '12px',
                      backgroundColor: '#fff'
                  },
                  search: true,
                  sorting: true,
                  showTitle: false,
                  draggable: false,
                  searchFieldStyle: {
                      width: '384px',
                      height: '35px',
                      borderRadius: '4px',
                      boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.1), inset 0 1px 3px 0 rgba(236, 236, 236, 0.5)',
                      border: 'solid 1px rgba(0, 0, 0, 0.1)',
                      backgroundColor: '#fff',
                      marginLeft: '10px'
                },
                minBodyHeight: '50vh',
                maxBodyHeight: '60vh'
                
              }}
            />

            </ThemeProvider>
      </div>
  )
}

export default Table