import React, { Fragment, useState } from 'react'
import { SelectListSimple } from '../../bdb-components/SelectListSimpleComponent/SelectListSimple'
import { productsDocuments, typeIdentification } from '../../../constants'
import { BackLink } from '../../bdb-components/BackLink/BackLink'
import { useForm } from '../../../hooks/useForm'
import { useEffect } from 'react'
import { ConfirmationModal } from '../../bdb-components/Modals/ConfirmationModal'
import { disableError, getDocumentsTypeByProduct, resetModalConfirmation, saveFormOrderDisbursement } from '../../bdb-actions/bdbDocumentsActions'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import { useDispatch, useSelector } from 'react-redux'
import { ConfirmationSmall } from '../../bdb-components/Toasts/ConfirmationSmall'
import Loading from '../../bdb-components/Modals/Loading'
import { ErrorModal } from '../../bdb-components/Modals/ErrorModal'
import '../../../assets/styles/margin.scss'
import '../../../assets/styles/titles.scss'
import '../../../assets/styles/flexbox.scss'
import '../../../assets/styles/inputs.scss'
import '../../../assets/styles/buttons.scss'
import '../../../assets/styles/icons.scss'

const ReportOrder = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const showModal = useSelector(state => state.documentsReducer.confirmationModal);
    const typesDocs = useSelector(state => state.documentsReducer.documentsTypes);
    const dataForm = useSelector(state => state.documentsReducer.dataForm);
    const loading = useSelector(state => state.documentsReducer.loading);
    const error = useSelector(state => state.documentsReducer.error);
    
    const [ formValues, handleInputChange ] = useForm(dataForm);
    const { productObj, identiCustomer, typeCustomerObj, numberControl, creditProject } = formValues;
    const [ enableSubmit, setEnableSubmit] = useState(true);
    const [ showConfirmationModal, setShowConfirmationModal] = useState(false);
    
    const handleSubmit = () => {
        setShowConfirmationModal(true);
    }
    const handelSubmitModal = ( { target } ) => {
        if ( target.id === "Accept"){
            setShowConfirmationModal(false);
            dispatch(saveFormOrderDisbursement(formValues));
        }
        if ( target.id === "Cancel") setShowConfirmationModal(false);
    }
    const handleModalSmall = () => {
        dispatch(resetModalConfirmation());
    }
    const timeoutModal = () => {
        setTimeout(() => {
            handleModalSmall();
        }, 15000);
    }
    const handleError = () => {
        dispatch( disableError());
    }
    const handleClickSends = (e) => {
        history.push('/order-sends')
    }
    useEffect(() => {
        setEnableSubmit( !validityForm(formValues) )
    }, [formValues])

    useEffect(() => {
        if (!!showModal){
            timeoutModal();
        }
    }, [showModal])
    useEffect(() => {
        if (!error) dispatch(getDocumentsTypeByProduct(dataForm.productObj.id,dataForm.typeCustomerObj.id));
    }, [dataForm, error, dispatch]);
    useEffect(() => {
        if (typesDocs.length !== 0){
            history.push('/documents');
        } 
    }, [typesDocs, history]);
    
    
    return (
        <Fragment>
            <Loading show={loading} />
            <ErrorModal 
                show={error}
                handleButton={handleError}/>
            <BackLink url={'backHomeDigitalFoler'}/>
            <div className="report-order-margin-content-row">
                <div className="d-flex" style={{"marginBottom" : "-1%"}}>
                    <div className="report-order-title" style={{"marginRight" : "125px"}}>Orden de Desembolso</div>
                    <button onClick={ handleClickSends }  className="btn-sends" >Enviadas &#62;</button>

                </div>

                <div className="fields-obligatory-subtitle">Todos los campos son obligatorios</div>
                <div className="report-order-section">
                    <div className="report-order-box">
                        <div className="report-order-row">
                            <div className="report-order-full-box">
                                <div className="report-order-box-middle report-order-box-label">Producto a desembolsar</div>
                            </div>
                            <div className="report-order-full-box">
                                    <SelectListSimple 
                                        width={'100%'}
                                        optionsList={ productsDocuments }
                                        idName={'productObj'}
                                        handleForm={handleInputChange}
                                        optionSelected={ productObj }
                                        />
                            </div>
                        </div>
                        <div className="report-order-row" style={ validityLength(identiCustomer, 6) && identiCustomer !== '' ? {"margin" : "4% 0% -15px 0%"} : {}}>
                            <div className="report-order-full-box">
                                <div className="report-order-box-middle report-order-box-label">Tipo documento cliente</div>
                                <div className="report-order-box-middle report-order-box-label">Documento cliente</div>

                            </div>
                            <div className="report-order-full-box">
                                <div className="report-order-box-middle">
                                    <SelectListSimple 
                                        width={'272px'}
                                        optionsList={ typeIdentification }
                                        idName={'typeCustomerObj'}
                                        handleForm={handleInputChange}
                                        optionSelected={ typeCustomerObj }
                                        />
                                </div>
                                <div>
                                    
                                </div>
                                <input 
                                    className="report-order-box-middle report-order-input"
                                    placeholder="Ingresa un número"
                                    name="identiCustomer"
                                    value={ identiCustomer}
                                    minLength="6"
                                    maxLength="15"
                                    onChange={ (e) => handleInputChange(e, true) } 
                                ></input>

                            </div>
                            <div className="box-error">
                                <span className="span-error-length">{ (validityLength(identiCustomer, 6) && identiCustomer !== '') && 'El documento debe tener mínimo 6 dígitos'}</span>

                            </div>
                        </div>
                        <div className="report-order-row">
                            <div className="report-order-full-box">
                                <div className="report-order-box-middle report-order-box-label">Número de control</div>
                                <div className="report-order-box-middle report-order-box-label">Proyecto crediticio</div>
                            </div>
                            <div className="report-order-full-box">
                                <input
                                    className="report-order-box-middle report-order-input"
                                    placeholder="Ingresa un número"
                                    name="numberControl"
                                    value={numberControl}
                                    maxLength="20"
                                    onChange={ (e) => handleInputChange(e, true) }
                                />
                                <input 
                                    className="report-order-box-middle report-order-input"
                                    placeholder="Ingresa un número"
                                    name="creditProject"
                                    value={creditProject}
                                    maxLength="20"
                                    onChange={ (e) => handleInputChange(e, true) }
                                />
                            </div>
                        </div>
                        <ConfirmationSmall 
                            show={showModal}
                            message={'La Orden de Desembolso se envió con éxito'}
                            handleClick={handleModalSmall}/>
                        <div className="report-order-row d-flex justify-content-end">
                            <button 
                                className="btn-order"
                                disabled={ enableSubmit }
                                onClick={ handleSubmit }>Continuar</button>
                        </div>

                    </div>
                </div>
            </div>
            <ConfirmationModal 
                show={showConfirmationModal}
                bodyContent={(
                    <p>Número de control: {numberControl} <br/>
                    Proyecto crediticio: {creditProject}
                    </p>
                )}
                handleButton={handelSubmitModal}/>
        </ Fragment>
    )
}
export default ReportOrder;

function validityObjectNull ( obj ) {
    return Object.keys(obj).length !== 0;
}

function validityForm ( form ) {
    let validProduct, validType , validIdenti, validNumber, validCredit = false;
    validProduct = validityObjectNull( form.productObj );
    validType = validityObjectNull( form.typeCustomerObj )
    validIdenti = !validityLength( form.identiCustomer, 6);
    validNumber = !validityLength( form.numberControl, 1);
    validCredit = !validityLength( form.creditProject, 1);
    return validProduct && validType && validIdenti && validNumber && validCredit;
}

function validityLength ( value , limit) {
    return value.length < limit;


}
