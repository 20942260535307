import {
    GET_DOCUMENT_TYPE,
    GET_DOCUMENT_TYPE_SUCCESS,
    GET_DOCUMENT_TYPE_FAIL,
    POST_DOCUMENT_TYPE,
    POST_DOCUMENT_TYPE_SUCCESS,
    POST_DOCUMENT_TYPE_FAIL,
    GET_DOCUMENT_REQUEST_NUMBER,
    GET_DOCUMENT_REQUEST_NUMBER_SUCESS,
    GET_DOCUMENT_REQUEST_NUMBER_FAIL,
    UPLOAD_DOCUMENTS_CACHE,
    POST_ORDER_START,
    POST_ORDER_SUCCESS,
    POST_ORDER_FAIL,
    RESET_MODAL,
    RESET_INFO,
    PREVIEW_DOCUMENT,
    RESET_DOCUMENT_PREVIEW,
    UPDATE_PREVIEW_PROGRESS,
    SET_ERROR,
    SET_DELETE_MODAL,
    SET_INIT_FORM_VALUES,
    SET_INIT_DOCUMENTS_TYPES,
    START_LOADING,
    FINISH_LOADING,
    SHOW_MODAL_ERROR,
    HIDE_MODAL_ERROR, SHOW_CONFIRMATION_MODAL, ORDERS_DISBURSEMENT_SUCESS
} from '../bdb-types/index';
import { initFormValues } from '../../constants';

const initialState = {
    documentsTypes: [],
    previewedDocument: undefined,
    error: false,
    loading: false,
    documentsByIdRequest: [],
    srcCurrentDocument:undefined,
    dataForm: initFormValues,
    confirmationModal : false,
    showDeleteModal : false,
    orders: []
}
const resetState = {
    documentsTypes: [],
    srcCurrentDocument:undefined,
    dataForm: initFormValues,
}

function bdbDocumentsReducer(state = initialState, action) {
    switch (action.type) {
        case GET_DOCUMENT_TYPE: case POST_DOCUMENT_TYPE: case POST_DOCUMENT_TYPE_FAIL: case GET_DOCUMENT_REQUEST_NUMBER:
            case POST_ORDER_START: case UPDATE_PREVIEW_PROGRESS:
            return {
                ...state,
                error: false,
                loading: action.payload
            }
        case GET_DOCUMENT_TYPE_SUCCESS: case SET_INIT_DOCUMENTS_TYPES :
            return {
                ...state,
                loading: false,
                documentsTypes: action.payload
            }
        case GET_DOCUMENT_TYPE_FAIL:
            return {
                ...state,
                error: true,
                loading: false,
                documentsTypes: action.payload
            }    
        case POST_DOCUMENT_TYPE_SUCCESS: 
            return {
                ...state,
                loading: false
            }
        case UPLOAD_DOCUMENTS_CACHE:
            return {
                ...state,
                documentsTypes: action.payload
            }
        case GET_DOCUMENT_REQUEST_NUMBER_SUCESS: case GET_DOCUMENT_REQUEST_NUMBER_FAIL:
            return {
                ...state,
                loading: false,
                documentsByIdRequest: action.payload
            }
        case POST_ORDER_SUCCESS: case SET_INIT_FORM_VALUES: 
            return {
                ...state,
                loading: false,
                dataForm: action.payload
            }
        case POST_ORDER_FAIL:
            return {
                ...state,
                error: true,
                loading: action.payload
            }    
        case RESET_MODAL: 
            return {
                ...state,
                confirmationModal: false
            }
        case RESET_INFO:
            return {
            ...state, ...resetState
            }
        case PREVIEW_DOCUMENT:
            return {
                ...state,
                documentsTypes: state.documentsTypes.map( e => e.index === action.payload.index ? action.payload : e ),
                previewedDocument: action.payload,
                loading: false
            }
        case RESET_DOCUMENT_PREVIEW:
            return {
                ...state,
                previewedDocument: { ...state.previewedDocument, src: undefined }
            }
        case SET_ERROR:
            return {
                ...state,
                error: action.payload
            }  
        case SET_DELETE_MODAL:
            return {
                ...state,
                showDeleteModal: action.payload
            }
        case START_LOADING:
            return {
                ...state,
                loading: true
            };
        case FINISH_LOADING:
            return {
                ...state,
                loading: false
            };
        case SHOW_MODAL_ERROR:
            return {
                ...state,
                error: true
            }
        case HIDE_MODAL_ERROR:
            return {
                ...state,
                error: false
            }
        case SHOW_CONFIRMATION_MODAL:
            return {
                ...state,
                confirmationModal: true,
            };
        case ORDERS_DISBURSEMENT_SUCESS:
            return {
                ...state,
                orders: [ ...action.payload ],
            }    
        default:
            return state;
    }

}

export default bdbDocumentsReducer;
